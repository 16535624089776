/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Tiptap */
.telegram-text-editor {
  border-radius: 8px;
  border: 1px solid #e6e8f0;
  overflow: hidden;
  /* border: 1px solid rgba(29, 28, 29, 0.15); */
}

.telegram-text-editor .tiptap {
  padding: 1rem;
  outline: none;
  font-size: 14px;
  max-height: 300px;
  overflow: auto;
}

.telegram-text-editor .tiptap p {
  margin: 0px 0px;
}

.telegram-text-editor .tiptap p:first-child {
  margin-top: 0px;
}
 
.telegram-text-editor .tiptap p:last-child {
  margin-bottom: 0px;
}

.telegram-text-editor .tiptap blockquote {
  position: relative;
  padding-left: 16px;
  margin: 0px 16px;
}

.telegram-text-editor .tiptap blockquote:before {
  background: #7f7f7f;
  content: "";
  border-radius: 8px;
  width: 4px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.telegram-text-editor .tiptap code {
  color: #ff7e00;
}

.telegram-text-editor .tiptap pre {
  background: #0d0d0d;
  border-radius: 0.5rem;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
}

.telegram-text-editor .tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.telegram-text-editor .bubble-menu-light {
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgba(29, 28, 29, 0.3);
  padding: 4px;
  font-size: small;
}

.new-line-translation {
  white-space: pre-line;
}